import React from 'react';
import { Store, AnyAction } from 'redux';
import { Provider } from 'react-redux';
import Route from './route';
import { IAppState } from './store';

interface IProps {
    store: Store<IAppState, AnyAction>;
}

const Root: React.FC<IProps> = ({
    store,
}) => {
    return (
        <Provider store={store}>
            <Route />
        </Provider>
    );
}

export default Root;