import { Action, ActionCreator, Dispatch, Reducer } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { neverReached } from '.';
import axios from 'axios';
import { Guid } from 'guid-typescript';

// Store
export interface IBinaryQueryItem {
    id: Guid;
    value: string;
}

export interface IBinaryQuery {
    item1: IBinaryQueryItem;
    item2: IBinaryQueryItem;
}

export interface IBinaryDecisionState {
    readonly currentQuery: IBinaryQuery | undefined;
    readonly loading: boolean;
}

export const initialState: IBinaryDecisionState = {
    currentQuery: undefined,
    loading: false,
};

// Actions
interface IGettingQueryAction extends Action<'GettingQuery'> {}

interface IGotQueryAction extends Action<'GotQuery'> {
    query: IBinaryQuery;
}

interface INoQueryAction extends Action<'NoQuery'> {}

interface ISendingDecisionAction extends Action<'SendingDecision'> {}

interface ISentDecisionAction extends Action<'SentDecision'> {}

type KnownActions =
    | IGettingQueryAction
    | IGotQueryAction
    | INoQueryAction
    | ISendingDecisionAction
    | ISentDecisionAction;

// Action Creators
export const getNextQueryActionCreator: ActionCreator<
  ThunkAction<
    Promise<boolean>,             // The type of the last action to be dispatched - will always be promise<T> for async actions
    IBinaryQuery,           // The type for the data within the last action
    null,                      // The type of the parameter for the nested function 
    IGotQueryAction            // The type of the last action to be dispatched
  >
> = (id: Guid) => {
    return async (dispatch: Dispatch) => {
        const gettingQueryAction: IGettingQueryAction = {
            type: 'GettingQuery',
        };
        dispatch(gettingQueryAction);

        interface GetNextBinaryDecisionResponse {
            item1Id: Guid;
            item1Value: string;
            item2Id: Guid;
            item2Value: string;
        }

        let moreQuestions: boolean = false;
        try {
            const nextDecision: GetNextBinaryDecisionResponse = (await axios.get(
                process.env.REACT_APP_API_ROOT_URL + 'prioritylist/' + id + '/getnextbinarydecision', {
                    headers: { 
                        'Access-Control-Allow-Origin': '*'
                    }
                })).data;

            const gotQueryAction: IGotQueryAction = {
                query: {
                    item1: {
                        id: nextDecision.item1Id,
                        value: nextDecision.item1Value,
                    },
                    item2: {
                        id: nextDecision.item2Id,
                        value: nextDecision.item2Value,
                    },
                },
                type: 'GotQuery',
            };
    
            moreQuestions = true;
            dispatch(gotQueryAction);
        }
        catch (e) {
            if (e.isAxiosError && 
                e.response !== undefined &&
                (e.response.status === 400 || e.response.status === 404)) {
                const noQueryAction: INoQueryAction = {
                    type: 'NoQuery',
                };
        
                dispatch(noQueryAction);
            }
        }

        return moreQuestions;
    };
};

export const sendDecisionActionCreator: ActionCreator<
  ThunkAction<
    Promise<void>,             // The type of the last action to be dispatched - will always be promise<T> for async actions
    IBinaryQuery,              // The type for the data within the last action
    null,                      // The type of the parameter for the nested function 
    IGotQueryAction            // The type of the last action to be dispatched
  >
> = (listId: Guid, item1Id: Guid, item2Id: Guid) => {
    return async (dispatch: Dispatch) => {
        const SendingDecisionAction: ISendingDecisionAction = {
            type: 'SendingDecision',
        };
        dispatch(SendingDecisionAction);

        try {
            await axios.post(
                process.env.REACT_APP_API_ROOT_URL + 'prioritylist/' + listId + '/updateordering', 
                {
                    item1Id,
                    item2Id
                },
                {
                    headers: { 
                        'Access-Control-Allow-Origin': '*'
                    }
                });
        }
        catch (e) {
            alert(e);
        }

        const SentDecisionAction: ISentDecisionAction = {
            type: 'SentDecision',
        };
        dispatch(SentDecisionAction);
    };
};

// Reducers
export const binaryDecisionReducer: Reducer<IBinaryDecisionState, KnownActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case 'GettingQuery': {
            return {
                ...state,
                loading: true,
            };
        }
        case 'GotQuery': {
            return {
                ...state,
                currentQuery: action.query,
                loading: false,
            };
        }
        case 'NoQuery': {
            return {
                ...state,
                currentQuery: undefined,
                loading: false,
            };
        }
        case 'SendingDecision': {
            return {
                ...state,
                loading: true,
            };
        }
        case 'SentDecision': {
            return {
                ...state,
                loading: false,
                currentQuery: undefined,
            };
        }
        default:
            neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
  };