import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { getPeopleActionCreator, IGotPeopleAction } from '../store/people';
import { IPerson } from '../store/people';

interface IProps {
    counter: number;
    people: IPerson[];
    loadPeople: () => Promise<IGotPeopleAction>;
    message?: string;
}

const CounterAndLastPersonWithProps: React.FC<IProps> = ({
    counter,
    people,
    loadPeople,
    message
}) => {
    React.useEffect(() => {
        loadPeople();
    }, [loadPeople]);

    return (
        <div>
            Count is: {counter}
            Last person is {people.length > 0 ? people[people.length - 1].name : ""}
            <span hidden={message === undefined}>Message is: {message}</span>
        </div>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        counter: store.counterState.counter,
        people: store.peopleState.people
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        loadPeople: () => dispatch(getPeopleActionCreator())        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CounterAndLastPersonWithProps);