import React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import WithPropsAndReduxPage from './WithPropsAndRedux';
import FormPage from './FormPage';
import CreateListPage from './CreateListPage';
import NextBinaryQueryPage from './NextBinaryQueryPage';
import ViewListPage from './ViewListPage';
import '../css/PageLayout.css';

const AppRoute: React.FC = () => {
    const showNav: boolean = false;
    return (
        <div id="page">
            <BrowserRouter>
                <header>
                    <h1>prioritizemylife.com</h1>
                    <p>Put the important stuff first</p>
                    <nav hidden={!showNav}>
                        <ul>
                            <li>
                                <Link to='/'>Generate List</Link>
                            </li>
                            <li>
                                <Link to='/counterAndLastPerson'>Counter and Last Person</Link>
                            </li>
                            <li>
                                <Link to='/withPropsAndRedux'>With Props and Redux</Link>
                            </li>
                            <li>
                                <Link to='/pageWithForm'>Page with Form</Link>
                            </li>
                        </ul>
                    </nav>
                </header>
                <main>
                    <Route exact path='/' component={CreateListPage} />
                    <Route path='/viewList/:listId' render={route => <ViewListPage listId={route.match.params.listId} />} />
                    <Route path='/nextQuestion/:listId' render={route => <NextBinaryQueryPage listId={route.match.params.listId} />} />
                    <Route path='/withPropsAndRedux' component={WithPropsAndReduxPage} />
                    <Route path='/pageWithForm' component={FormPage} />
                </main>
            </BrowserRouter>
            <footer>
                &copy; prioritize.me {new Date().getFullYear()}
            </footer>
        </div>
    );
}

export default AppRoute;