import React from 'react';
import { connect } from 'react-redux';
import { createNewListActionCreator, IPriorityItem } from '../store/priorityList';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IAppState } from '../store';
import { Guid } from 'guid-typescript';
import '../css/PostList.css';
import '../css/Loading.css';

interface IProps {
    list: IPriorityItem[];
    posting: boolean;
    createNewList: () => Promise<Guid>;
}

const AddToList: React.FC<IProps> =  ({
    list,
    posting,
    createNewList,
}) => {
    const isHidden: boolean = list.length < 2;

    const handleClick = async () => {
        const newListId: Guid = await createNewList();
        document.location.href = '/nextquestion/' + newListId.toString();
    };

    return (
        <div className="postlist-main" hidden={isHidden}>
            <button disabled={posting} className="btn" onClick={() => handleClick()}>Finish</button>
            <div className="loading-spinner small" hidden={!posting}></div>
            <p>Is everything here now? If so, click "Finish" to create the list and start sorting!</p>
        </div>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        list: store.priorityListState.list,
        posting: store.priorityListState.posting,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        createNewList: () => dispatch(createNewListActionCreator())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddToList);