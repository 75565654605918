import React from 'react';
import CounterAndLastPersonWithProps from '../component/CounterAndLastPersonWithProps';

const WithPropsAndReduxPage: React.FC = () => {
    return (
        <div>
            <CounterAndLastPersonWithProps message="Hello world!" />
            <CounterAndLastPersonWithProps />
        </div>
    );
}

export default WithPropsAndReduxPage;