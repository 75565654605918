import React from 'react';
import { configureStore } from './store';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import Root from './Root';

const store = configureStore();

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <Root store={store} />
        </ThemeProvider>
    );
}

export default App;