import React from 'react';
import ComponentWithForm from '../component/ComponentWithForm';
import ComponentWithForm2, { ISimpleForm2Props } from '../component/ComponentWithForm2';

const sleep = (ms:number) => new Promise(resolve => setTimeout(resolve, ms));

async function showResults2(values: ISimpleForm2Props) {
  await sleep(500); // simulate server latency
  window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);
};

const FormPage: React.FC = () => {
    return (
        <div>
          <h2>Form 1</h2>
          <ComponentWithForm />
          <h2>Form 2</h2>
          <ComponentWithForm2 onSubmit={showResults2} />
        </div>
    );
}

export default FormPage;