import React from 'react';
import PriorityListDisplay from '../component/PriorityListDisplay';
import 'bootstrap/dist/css/bootstrap.css';
import { Guid } from 'guid-typescript';
import AddToList from '../component/AddToList';

interface IProps {
    listId: string;
}

const ViewListPage: React.FC<IProps> = ({
    listId,
}) => {
    if (Guid.isGuid(listId)) {
        return (
            <div>
                <PriorityListDisplay listId={Guid.parse(listId)} />
                <h3>Did you missing something? Add it to the list here:</h3>
                <AddToList />
            </div>
        );
    }
    else {
        return (
            <div>Invalid list ID</div>
        );
    }
}

export default ViewListPage;