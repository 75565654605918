import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: 'rgb(35, 8, 59)',
            contrastText: '#fff',
        },
        secondary: {
            main: 'rgb(216, 214, 71)',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: 'rgb(35, 8, 59)',
        },
    },
});

export default theme;