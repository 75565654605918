import React from 'react';
import AddToList from '../component/AddToList';
import PriorityListDisplay from '../component/PriorityListDisplay';
import 'bootstrap/dist/css/bootstrap.css'
import PostList from '../component/PostList';
import { Typography, Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    quote: {
        textAlign: 'center',
        maxWidth: 600,
        margin: '0 auto',
        fontStyle: 'italic',
        color: theme.palette.grey[600],
        "& .quoteBy": {
            color: theme.palette.grey[500],
            display: 'block',
        }
    },
});

interface IProps extends WithStyles<typeof styles> {
}

const CreateListPage: React.FC<IProps> = ({
    classes,
}) => {
    return (
        <div>
            <Typography variant="h3">Create a new list</Typography>
            <p>
                Add items to your list below. Once you have everything, we can start prioritizing!
            </p>
            <PriorityListDisplay />
            <AddToList />
            <PostList />
            <Typography variant="subtitle1" className={classes.quote}>
                "If it's your job to eat a frog, it's best to do it first thing in the morning. And If it's your job to eat two frogs, it's best to eat the biggest one first."
                <span className="quoteBy">~ Mark Twain</span>
            </Typography>
        </div>
    )
}

export default withStyles(styles)(CreateListPage);