import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../store'
import { IBinaryQuery, getNextQueryActionCreator, sendDecisionActionCreator } from '../store/binaryDecision';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Guid } from 'guid-typescript';
import '../css/NextQuestion.css';
import '../css/Loading.css';

interface IProps {
    listId: Guid;
    currentQuery: IBinaryQuery | undefined;
    loadingQuery: boolean;
    getNextQuery: (listId: Guid) => Promise<boolean>;
    sendDecision: (listId: Guid, item1Id: Guid, item2Id: Guid) => Promise<void>;
}

const NextBinaryDecisionDisplay: React.FC<IProps> = ({
    listId,
    currentQuery,
    loadingQuery,
    getNextQuery,
    sendDecision,
}) => {
    React.useEffect(() => {
        getNextQuery(listId);
    }, [getNextQuery, listId]);

    const handleClick = async (firstItem: Guid, secondItem: Guid) => {
        await sendDecision(listId, firstItem, secondItem);
        const moreQuestions: boolean = await getNextQuery(listId);

        if (!moreQuestions) {
            document.location.href = '/viewList/' + listId.toString();
        }
    };

    if (loadingQuery) {
        return (<div className="next-binary-question"><h3>Loading more questions</h3><div className="loading-spinner medium"></div></div>);
    }
    if (currentQuery === undefined) {
        return (<div>Nothing to see here</div>);
    }
    else {
        return (
            <div className="next-binary-question">
                <div className="row">
                    <button className="col-md-5 btn"
                            onClick={() => handleClick(currentQuery.item1.id, currentQuery.item2.id)}>
                        {currentQuery.item1.value}
                    </button>
                    <div className="col-md-2 separator">
                        <div>
                            vs
                        </div>
                    </div>
                    <button className="col-md-5 btn"
                            onClick={() => handleClick(currentQuery.item2.id, currentQuery.item1.id)}>
                        {currentQuery.item2.value}
                    </button>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (store: IAppState) => {
    return {
        currentQuery: store.binaryDecisionState.currentQuery,
        loadingQuery: store.binaryDecisionState.loading
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        getNextQuery: (listId: Guid) => dispatch(getNextQueryActionCreator(listId)),
        sendDecision: (listId: Guid, item1Id: Guid, item2Id: Guid) => 
            dispatch(sendDecisionActionCreator(listId, item1Id, item2Id))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NextBinaryDecisionDisplay);