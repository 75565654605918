import React from 'react';
import AddToListForm, { IAddPriorityItemForm } from './AddToListForm';
import { connect } from 'react-redux';
import { addItemActionCreator } from '../store/priorityList';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IAppState } from '../store';

interface IProps {
    loading: boolean;
    posting: boolean;
    addItem: (name: string) => Promise<void>;
}

const AddToList: React.FC<IProps> =  ({
    loading,
    posting,
    addItem,
}) => {
    async function addItemToStore(values: IAddPriorityItemForm) {
        await addItem(values.newItemValue);
    }

    return (
        <AddToListForm onSubmit={(values: IAddPriorityItemForm) => { addItemToStore(values); }} disableAll={loading || posting} />
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        loading: store.priorityListState.loading,
        posting: store.priorityListState.posting,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        addItem: (name: string) => dispatch(addItemActionCreator(name)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddToList);