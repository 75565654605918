import React from 'react';
import { connect } from 'react-redux';
import { IPriorityItem, getItemsActionCreator, deleteItemActionCreator, markItemCompletedActionCreator } from '../store/priorityList';
import { IAppState } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Guid } from 'guid-typescript';
import { Fab, Theme, createStyles, withStyles, WithStyles, Checkbox } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = (theme: Theme) => createStyles({
    deleteButton: {
        marginLeft: '0.5em',
    },
    completedItem: {
        textDecoration: 'line-through',
    },
    prioritizedList: {
        '&.newList': {
            maxHeight: '60vh',
            overflowY: 'auto',
        },
        '&.editList': {
            listStyleType: 'none',
            paddingInlineStart: '0',
            '& .unsortedItem': {
                color: 'red',
            },
            '& .unsavedItem': {
                fontWeight: '800',
            }
        },
        '& li': {
            padding: '0.5em',
        },
    },
});

interface IProps extends WithStyles<typeof styles> {
    listId?: Guid;
    list: IPriorityItem[];
    getItems: (listId: Guid | undefined) => Promise<void>;
    deleteItem: (listItemId: Guid) => Promise<void>;
    completeItem: (listItemId: Guid, newValue: boolean) => Promise<void>;
    listLoading: boolean;
}

const PriorityListDisplay: React.FC<IProps> = ({
    listId,
    list,
    getItems,
    deleteItem,
    completeItem,
    listLoading,
    classes,
}) => {
    React.useEffect(() => {
        getItems(listId);
    }, [getItems, listId]);

    const getItemClassName = (item: IPriorityItem) => {
        let classList: string[] = [];

        if (item.index === undefined || item.index === null) {
            classList = classList.concat('unsortedItem');
        }

        if (item.unsaved) {
            classList = classList.concat('unsavedItem');
        }

        if (item.completed) {
            classList = classList.concat(classes.completedItem);
        }

        return classList;
    }
    
    const listUnsorted: boolean = list.filter(item => item.index === undefined || item.index === null).length > 0;

    return (
        <div className="priority-list-display-main">
            <ul className={classes.prioritizedList + ' ' + (listId === undefined ? 'newList' : 'editList')}>
                {
                    list
                        .sort((item1, item2) => {
                            const item1Index: number = item1.completed ? Number.MAX_VALUE - 1 : (item1.index !== undefined && item1.index !== null) ? item1.index : Number.MAX_VALUE;
                            const item2Index: number = item2.completed ? Number.MAX_VALUE - 1 : (item2.index !== undefined && item2.index !== null) ? item2.index : Number.MAX_VALUE;

                            return item1Index - item2Index;
                        })
                        .map(item => (
                            <li key={item.id.toString()} className={getItemClassName(item).join(' ')}>
                                <Checkbox
                                    checked={item.completed}
                                    hidden={listId === undefined}
                                    onChange={() => completeItem(item.id, !item.completed)}
                                    inputProps={{
                                        'aria-label': `complete item ${item.id.toString()}`,
                                    }}
                                />
                                {item.value}
                                <Fab size="small"
                                    className={classes.deleteButton}
                                    aria-label="remove" 
                                    disabled={listLoading}
                                    hidden={listId !== undefined}
                                    onClick={() => deleteItem(item.id)}>
                                    <DeleteIcon fontSize="small"/>
                                </Fab>
                            </li>
                        ))
                }
            </ul>
            <div hidden={!listLoading}>
                Hold tight! Loading this list ...
                <div className="loading-spinner medium"></div>
            </div>
            <div hidden={!listUnsorted || listId === undefined}>
                Looks like you have some unsorted items. Click <a href={'/nextquestion/' + (listId === undefined ? '' : listId.toString())}>here</a> to sort them!
            </div>
        </div>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        list: store.priorityListState.list,
        listLoading: store.priorityListState.loading,
    };
};
  
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        getItems: (listId: Guid | undefined) => dispatch(getItemsActionCreator(listId)),
        deleteItem: (listItemId: Guid) => dispatch(deleteItemActionCreator(listItemId)),
        completeItem: (itemId: Guid, newValue: boolean) => dispatch(markItemCompletedActionCreator(itemId, newValue)),
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(PriorityListDisplay));