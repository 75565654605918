import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import '../css/AddToList.css';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export interface IAddPriorityItemForm {
    newItemValue: string;
}

interface IInlineProps {
    disableAll: boolean;
}

const AddToListForm: React.FC<InjectedFormProps<IAddPriorityItemForm, IInlineProps> & IInlineProps> = ({
    disableAll,
    handleSubmit,
    pristine,
    submitting,
}) => {
    return (
        <form onSubmit={handleSubmit} className="add-to-list-form form-inline">
            <div className="field">
                <label></label>
                <div>
                    <Field
                        name="newItemValue"
                        component="input"
                        type="text"
                        disabled={disableAll}
                        autoFocus
                    />
                </div>
            </div>
            <Fab size="small"
                 color="primary" 
                 aria-label="add" 
                 disabled={pristine || submitting || disableAll}
                 type="submit">
                <AddIcon />
            </Fab>
        </form>
    );
}

const ReduxFormName: string = 'addToListForm';
export { ReduxFormName };

export default reduxForm<IAddPriorityItemForm, IInlineProps>({
    form: ReduxFormName,
    onSubmitSuccess: (result, dispatch, props) => {
        if (props.reset !== undefined) {
            props.reset();
        }
        document.getElementsByName('newItemValue')[0].focus();
    }
})(AddToListForm);