import { Action, ActionCreator, Dispatch, Reducer } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { neverReached } from '.';

// Store
export interface ICounterState {
    counter: number;
}

// Actions
export interface IIncrement extends Action<'INCREMENT'> {}
export interface IDecrement extends Action<'DECREMENT'> {}

type CounterActions = IIncrement | IDecrement;

// Action Creators
export const increment: ActionCreator<
  ThunkAction<
    Promise<IIncrement>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    null,                       // The type of the parameter for the nested function 
    IIncrement            // The type of the last action to be dispatched
  >
> = () => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: 'INCREMENT' } as IIncrement);
  };
};

export const decrement: ActionCreator<
  ThunkAction<
    Promise<IDecrement>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    null,                       // The type of the parameter for the nested function 
    IDecrement            // The type of the last action to be dispatched
  >
> = () => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: 'DECREMENT' } as IDecrement);
  };
};

// Reducer
const initialCounterState: ICounterState = {
    counter: 0
};
export const counterReducer: Reducer<ICounterState, CounterActions>= (
    state = initialCounterState,
    action
) => {
    switch(action.type) {
        case "INCREMENT":
            return {
                ...state,
                counter: state.counter + 1
            }
        case "DECREMENT":
            return {
                ...state,
                counter: state.counter - 1
            }
        default:
            neverReached(action);
    }

    return state;
}