import { combineReducers, createStore, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import { IPeopleState, peopleReducer } from './people';
import { ICounterState, counterReducer } from './counter';
import { reducer as reduxFormReducer, FormStateMap } from 'redux-form';
import { IPriorityListState, priorityListReducer } from './priorityList';
import { IBinaryDecisionState, binaryDecisionReducer,  } from './binaryDecision';

// Store
export interface IAppState {
    readonly peopleState: IPeopleState;
    readonly counterState: ICounterState;
    readonly priorityListState: IPriorityListState;
    readonly form: FormStateMap;
    readonly binaryDecisionState: IBinaryDecisionState;
}
  
// tslint:disable-next-line:no-empty
export const neverReached = (never: never) => {};
  
const rootReducer = combineReducers<IAppState>({ 
    peopleState: peopleReducer,
    counterState: counterReducer,
    priorityListState: priorityListReducer,
    form: reduxFormReducer,
    binaryDecisionState: binaryDecisionReducer
});

export function configureStore(): Store<IAppState> {
    // This line is suspect, not sure if this is the middleware required
    const store = createStore(rootReducer, undefined, applyMiddleware(thunk));
    return store;
}