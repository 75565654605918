import { Action, ActionCreator, Dispatch, Reducer } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { neverReached, IAppState } from '.';
import axios from 'axios';
import { Guid } from 'guid-typescript';

// Store
export interface IPriorityItem {
    id: Guid,
    value: string;
    index: number | undefined;
    completed: boolean;
    unsaved: boolean;
}

export interface IPriorityListState {
    readonly currentListId?: Guid;
    readonly list: IPriorityItem[];
    readonly loading: boolean;
    readonly posting: boolean;
}

export const initialState: IPriorityListState = {
    list: [],
    loading: false,
    posting: false,
};

// Actions
interface IGettingItemsAction extends Action<'GettingItems'> {
}

interface IGotItemsAction extends Action<'GotItems'> {
    listId: Guid | undefined;
    items: IPriorityItem[];
}

interface IAddingItemAction extends Action<'AddingItem'> {}

interface IAddedItemAction extends Action<'AddedItem'> {
    newItem: IPriorityItem;
}

interface IMarkingItemCompleted extends Action<'MarkingItemCompleted'> {
    updatingItem: Guid;
}

interface IMarkedItemCompletedSuccess extends Action<'MarkedItemCompletedSuccess'> {
    itemId: Guid;
    newValue: boolean;
}

interface IDeletingItemAction extends Action<'DeletingItem'> {}

interface IDeletedItemAction extends Action<'DeletedItem'> {
    toDeleteId: Guid;
}

interface IPostingListAction extends Action<'PostingList'> {}

interface IPostedList extends Action<'PostedList'> {}

type KnownActions =
    | IGettingItemsAction
    | IGotItemsAction
    | IAddingItemAction
    | IAddedItemAction
    | IMarkingItemCompleted
    | IMarkedItemCompletedSuccess
    | IDeletingItemAction
    | IDeletedItemAction
    | IPostingListAction
    | IPostedList;

// Action Creators
export const getItemsActionCreator: ActionCreator<
    ThunkAction<
        Promise<void>,             // The type of the last action to be dispatched - will always be promise<T> for async actions
        IPriorityItem[],           // The type for the data within the last action
        null,                      // The type of the parameter for the nested function 
        IGotItemsAction            // The type of the last action to be dispatched
    >
> = (listId: Guid | undefined) => {
    return async (dispatch: Dispatch) => {
        if (listId === undefined) {
            const gotItemsAction: IGotItemsAction = {
                listId,
                items: [],
                type: 'GotItems',
            };

            dispatch(gotItemsAction);
        }
        else {
            const gettingItemsAction: IGettingItemsAction = {
                type: 'GettingItems',
            };
            dispatch(gettingItemsAction);

            const items: IPriorityItem[] = (await axios.get(
            process.env.REACT_APP_API_ROOT_URL + 'priorityList/' + listId.toString() + '/items', {
                headers: { 
                    'Access-Control-Allow-Origin': '*'
                }
            })).data.items;

            const gotItemsAction: IGotItemsAction = {
                listId,
                items: items,
                type: 'GotItems',
            };

            dispatch(gotItemsAction);
        }
    };
};

export const addItemActionCreator: ActionCreator<
    ThunkAction<
        Promise<void>,    // The type of the last action to be dispatched - will always be promise<T> for async actions
        IAppState,        // The type for the data within the last action
        IPriorityItem,    // The type of the parameter for the nested function 
        IAddedItemAction  // The type of the last action to be dispatched
    >
> = (newItem: string) => {
    return async (dispatch: Dispatch, getState: () => IAppState) => {
        const postingItemAction: IAddingItemAction = {
            type: 'AddingItem',
        };
        dispatch(postingItemAction);

        const currentListId = getState().priorityListState.currentListId;
        let newItemId: Guid = Guid.create();
        let unsaved: boolean = true;
        if (currentListId !== undefined) {
            const newListIdString: string = (await axios.post(
                `${process.env.REACT_APP_API_ROOT_URL}priorityList/${currentListId.toString()}/items`,
                {
                    newItemValue: newItem,
                },
                {
                    headers: { 
                        'Access-Control-Allow-Origin': '*'
                    }
                })).data.newListItemId;

            newItemId = Guid.parse(newListIdString);
            unsaved = false;
        }

        const addItemAction: IAddedItemAction = {
            type: 'AddedItem',
            newItem: {
                id: newItemId,
                value: newItem,
                index: undefined,
                unsaved,
                completed: false,
            }
        };
        dispatch(addItemAction);
    };
};

export const markItemCompletedActionCreator: ActionCreator<
    ThunkAction<
        Promise<void>,    // The type of the last action to be dispatched - will always be promise<T> for async actions
        IAppState,        // The type for the data within the last action
        IPriorityItem,    // The type of the parameter for the nested function 
        IAddedItemAction  // The type of the last action to be dispatched
    >
> = (itemId: Guid, newCompleted: boolean) => {
    return async (dispatch: Dispatch, getState: () => IAppState) => {
        const markingItemCompletedAction: IMarkingItemCompleted = {
            type: 'MarkingItemCompleted',
            updatingItem: itemId,
        };
        dispatch(markingItemCompletedAction);

        const currentListId = getState().priorityListState.currentListId;
        if (currentListId !== undefined) {
            await axios.post(
                `${process.env.REACT_APP_API_ROOT_URL}priorityList/${currentListId.toString()}/items/${itemId}`,
                {
                    completed: newCompleted,
                },
                {
                    headers: { 
                        'Access-Control-Allow-Origin': '*'
                    }
                });
        }

        const markedItemCompletedSuccessAction: IMarkedItemCompletedSuccess = {
            type: 'MarkedItemCompletedSuccess',
            itemId,
            newValue: newCompleted,
        };
        dispatch(markedItemCompletedSuccessAction);
    };
};

export const deleteItemActionCreator: ActionCreator<
    ThunkAction<
        Promise<void>, // The type of the last action to be dispatched - will always be promise<T> for async actions
        null,                      // The type for the data within the last action
        IPriorityItem,             // The type of the parameter for the nested function 
        IAddedItemAction           // The type of the last action to be dispatched
    >
> = (toDeleteId: Guid) => {
    return async (dispatch: Dispatch) => {
        const deletingItemAction: IDeletingItemAction = {
            type: 'DeletingItem',
        };
        dispatch(deletingItemAction);

        const deletedItemAction: IDeletedItemAction = {
            type: 'DeletedItem',
            toDeleteId: toDeleteId,
        };
        dispatch(deletedItemAction);
    };
};

export const createNewListActionCreator: ActionCreator<
    ThunkAction<
        Promise<Guid>,             // The type of the last action to be dispatched - will always be promise<T> for async actions
        IAppState,                      // The type for the data within the last action
        null,                      // The type of the parameter for the nested function 
        IAddedItemAction           // The type of the last action to be dispatched
    >
> = () => {
    return async (dispatch: Dispatch, getState: () => IAppState) => {
        const postingListAction: IPostingListAction = {
            type: 'PostingList',
        };
        dispatch(postingListAction);

        const newListid: Guid = (await axios.post(
            process.env.REACT_APP_API_ROOT_URL + 'priorityList/', 
            getState().priorityListState.list.map(item => item.value),
            {
                headers: { 
                    'Access-Control-Allow-Origin': '*'
                }
            })).data.newListId;

        const postedListAction: IPostedList = {
            type: 'PostedList',
        };
        dispatch(postedListAction);

        return newListid;
    };
};

// Reducers
export const priorityListReducer: Reducer<IPriorityListState, KnownActions> = (
    state = initialState,
    action,
  ) => {
    switch (action.type) {
        case 'GettingItems': {
            return {
                ...state,
                list: [],
                loading: true,
            };
        }
        case 'GotItems': {
            return {
                ...state,
                currentListId: action.listId,
                list: action.items,
                loading: false,
            };
        }
        case 'AddingItem': {
            return {
                ...state,
                posting: true,
            };
        }
        case 'AddedItem': {
            return {
                ...state,
                posting: false,
                list: state.list.concat(action.newItem),
            };
        }
        case 'MarkingItemCompleted': {
            const updatedList = state.list.slice();
            updatedList.map(item => {
                if (item.id === action.updatingItem) {
                    item.unsaved = true;
                }

                return 0;
            });

            return {
                ...state,
                loading: true,
                list: updatedList,
            }
        }
        case 'MarkedItemCompletedSuccess': {
            const updatedList = state.list.slice();
            updatedList.map(item => {
                if (item.id === action.itemId) {
                    item.completed = action.newValue;
                    item.unsaved = false;
                }

                return 0
            });

            return {
                ...state,
                loading: false,
                list: updatedList,
            }
        }
        case 'DeletingItem': {
            return {
                ...state,
                posting: true,
            };
        }
        case 'DeletedItem': {
            return {
                ...state,
                posting: false,
                list: state.list.filter(item => item.id !== action.toDeleteId),
            };
        }
        case 'PostingList': {
            return {
                ...state,
                posting: true,
            };
        }
        case 'PostedList': {
            return {
                ...state,
                posting: false,
            };
        }
        default:
            neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
  };