import React from 'react';
import { Guid } from 'guid-typescript';
import BinaryDecisionDisplay from '../component/NextBinaryDecisionDisplay';

interface IProps {
    listId: string
}

const NextBinaryQueryPage: React.FC<IProps> = ({
    listId
}) => {
    if (Guid.isGuid(listId)) {
        return (
            <div>
                <h2>Which is more important?</h2>
                <BinaryDecisionDisplay listId={Guid.parse(listId)} />
                View this list again by clicking <a href={'/viewlist/' + listId.toString()}>here</a>
            </div>);
    }
    else {
        return (
            <div>
                Invalid list Id
            </div>
        );
    }
}

export default NextBinaryQueryPage;