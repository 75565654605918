import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

export interface ISimpleFormProps {
  firstName: string;
  sex: string;
}

const SimpleForm: React.FC<InjectedFormProps<ISimpleFormProps>> = ({
  handleSubmit,
  pristine,
  submitting,
  reset
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>First Name</label>
        <div>
          <Field
            name="firstName"
            component="input"
            type="text"
            placeholder="First Name"
          />
        </div>
      </div>
      <div>
        <label>Last Name</label>
        <div>
          <Field
            name="lastName"
            component="input"
            type="text"
            placeholder="Last Name"
          />
        </div>
      </div>
      <div>
        <label>Email</label>
        <div>
          <Field
            name="email"
            component="input"
            type="email"
            placeholder="Email"
          />
        </div>
      </div>
      <div>
        <label>Sex</label>
        <div>
          <label>
            <Field name="sex" component="input" type="radio" value="male" />
            {' '}
            Male
          </label>
          <label>
            <Field name="sex" component="input" type="radio" value="female" />
            {' '}
            Female
          </label>
        </div>
      </div>
      <div>
        <label>Favorite Color</label>
        <div>
          <Field name="favoriteColor" component="select">
            <option />
            <option value="ff0000">Red</option>
            <option value="00ff00">Green</option>
            <option value="0000ff">Blue</option>
          </Field>
        </div>
      </div>
      <div>
        <label htmlFor="employed">Employed</label>
        <div>
          <Field
            name="employed"
            id="employed"
            component="input"
            type="checkbox"
          />
        </div>
      </div>
      <div>
        <label>Notes</label>
        <div>
          <Field name="notes" component="textarea" />
        </div>
      </div>
      <div>
        <button type="submit" disabled={pristine || submitting}>Submit</button>
        <button type="button" disabled={pristine || submitting} onClick={reset}>
          Clear Values
        </button>
      </div>
    </form>
  );
};

const SimpleReduxForm = reduxForm<ISimpleFormProps>({
  form: 'simple', // a unique identifier for this form
})(SimpleForm);

const ToExport: React.FC = () => {
  const sleep = (ms:number) => new Promise(resolve => setTimeout(resolve, ms));
  
  async function showResults(values: ISimpleFormProps) {
    await sleep(500); // simulate server latency
    window.alert(`You submitted simple form 1:\n\n${JSON.stringify(values, null, 2)}`);
  };
  
  return (
    <SimpleReduxForm onSubmit={showResults} />
  );
}

export default ToExport;